import ga4 from 'react-ga4'
import consts from 'src/assets/config-front.json'
import { UaEventOptions } from 'react-ga4/types/ga4'
import { isDev } from '../runtime'

const TRACKING_ID = consts.GA_TRACKING_ID

export const init = () => {
  ga4.initialize(TRACKING_ID, {
    testMode: isDev,
  })
}

export const sendEvent = (event: UaEventOptions) => {
  ga4.event(event)
}

export const sendPageview = (path: string) => {
  ga4.send({
    hitType: 'pageview',
    page: path,
  })
}
