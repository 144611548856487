import Image from 'next/image'
import Link from 'next/link'
import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import logo from 'src/assets/images/logo-fi.svg'
import { AuthContext } from 'src/context/AuthContext'
import { useRouter } from 'next/router'
import styles from './NavBar.module.scss'

const NavBar = () => {
  const { t } = useTranslation(),
    [menuActive, setMenuActive] = useState(false),
    { authenticated, setAuthenticated } = useContext(AuthContext),
    { push } = useRouter()
  const doLogout = useCallback(() => {
    setAuthenticated(undefined)
    setMenuActive(false)
    push('/login')
  }, [push, setAuthenticated])
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" href="/">
          <Image
            className={styles.logoImg}
            src={logo}
            alt={t('logoText')}
            style={{ maxHeight: 'unset' }}
            priority={true}
          />
          {/*<span>
            <strong>{t('logoText')}</strong>
           </span>*/}
        </Link>

        <div
          role="button"
          className={`navbar-burger navbar-end ${menuActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setMenuActive(!menuActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${menuActive ? 'is-active' : ''}`}>
        {authenticated && (
          <div className="navbar-start">
            <a className="navbar-item" onClick={doLogout}>
              {t('navbarLogout')}
            </a>
          </div>
        )}
      </div>
    </nav>
  )
}

export default NavBar
